import { Component } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-top',
  templateUrl: './top.component.html',
  styleUrls: ['./top.component.scss']
})
export class TopComponent {
  isLoggedIn: boolean = false;

  constructor(public ks: KeycloakService) {
  }

  ngOnInit(): void {
    this.isLoggedIn = this.ks.isLoggedIn();
  }

  login() {
    this.ks.login();
  }
}
