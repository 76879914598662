import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {DataPoint} from "./data-point";

@Injectable({
  providedIn: 'root'
})
export class SystemDataService {

  constructor(private http: HttpClient) {
  }

  loadAllData(system: string, timeRange?: string) {
    let params = new HttpParams().set('system', system);
    params = params.set('timeRange', timeRange ? timeRange : '-1d');
    return this.http.get<DataPoint[]>(`${environment.api}/influx`, {params});
  }

}
