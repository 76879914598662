import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { MenuItem } from 'primeng/api';
import { KeycloakProfile } from 'keycloak-js';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit{

  isLoggedIn: boolean = false;
  userprofile?: KeycloakProfile;
  items: MenuItem[] = [];

  constructor(public ks: KeycloakService) {
  }

  async ngOnInit() {

    this.isLoggedIn = await this.ks.isLoggedIn();

    if(this.isLoggedIn) {
      this.userprofile = await this.ks.loadUserProfile();
    }

    // this.ks.isLoggedIn().then( value => {
    //   this.isLoggedIn = value;
    //   if(this.isLoggedIn) {
    //     this.ks.loadUserProfile().then( up => this.userprofile = up);
    //   }
    // });

    this.items = [
      {
        label: 'Logout',
        icon: 'pi pi-power-off',
        command: () => this.ks.logout()
      }
    ];
  }


}
