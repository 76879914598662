import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ButtonModule } from 'primeng/button';
import { StartPageComponent } from './pages/start-page/start-page.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import { TableModule } from 'primeng/table';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LangSwitcherComponent } from './components/lang-switcher/lang-switcher.component';
import {KeycloakAngularModule, KeycloakBearerInterceptor, KeycloakService} from 'keycloak-angular';
import { environment } from '../environments/environment';
import { SecurePageComponent } from './pages/secure-page/secure-page.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { AvatarModule } from 'primeng/avatar';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TopComponent } from './components/top/top.component';
import { PanelModule } from 'primeng/panel';
import {InputTextModule} from "primeng/inputtext";
import {TagModule} from "primeng/tag";
import {ContactComponent} from "./components/contact/contact.component";
import {SupervisorComponent} from "./components/supervisor/supervisor.component";
import {SystemDetailPageComponent} from "./pages/system-detail-page/system-detail-page.component";
import {GlobalErrorHandler} from "./GlobalErrorHandler";
import {provideRouter} from "@angular/router";

// AoT requires an exported function for factories
export function HttpLoaderFactory( http: HttpClient ) {
  return new TranslateHttpLoader( http );
}

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>

    keycloak.init({
      config: {
        url: environment.keycloak.url,
        realm: environment.keycloak.realm,
        clientId: environment.keycloak.clientId
      },
      initOptions: {
        onLoad: 'check-sso',
        checkLoginIframe: false,
        redirectUri: environment.keycloak.redirectUri
      },
      enableBearerInterceptor: true,
      bearerPrefix: 'Bearer',
    }).then(authenticated => {
      console.log('User is authenticated:', authenticated);
    }).catch(err => {
      console.log(err);
      console.error('Keycloak init failed:', err);
    });

}

@NgModule( {
  declarations: [
    AppComponent,
    StartPageComponent,
    LangSwitcherComponent,
    SecurePageComponent,
    AvatarComponent,
    TopComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AppRoutingModule,
    KeycloakAngularModule,
    ButtonModule,
    TableModule,
    AvatarModule,
    TieredMenuModule,
    PanelModule,
    InputTextModule,
    TagModule,
    ContactComponent,
    SupervisorComponent
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    },
    KeycloakService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KeycloakBearerInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
} )
export class AppModule {
}
