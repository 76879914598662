<div class="flex justify-content-center align-content-center h-2rem bg-primary">
  <div class="flex justify-content-center align-items-center">

    <div class="flex gap-3">
      <div class="flex">
        <span class="font-bold">Kälte- und Systemtechnik GmbH</span>
      </div>
      <div class="flex">
        <i class="pi pi-map-marker"></i> <span class="text-white no-underline ml-1" >Strassfeld 5, 3441 Freundorf</span>
      </div>
      <div class="flex">
        <i class="pi pi-phone"></i> <a class="text-white no-underline ml-1"  href="tel:+43227444109"> +43 2274 44 109</a>
      </div>
      <div class="flex">
        <i class="pi pi-envelope"></i> <a class="text-white no-underline ml-1" href="mailto:office@kuste.at"> office&#64;kustec.at</a>
      </div>
      <div class="flex">
        Service: <a class="text-white no-underline ml-1" href="tel:+436602017663"> +43 660 201 76 63</a>
      </div>
    </div>

  </div>

</div>

