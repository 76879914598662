import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, RouterModule} from "@angular/router";

@Component({
  selector: 'app-system-edit-page',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './system-edit-page.component.html',
  styles: ``
})
export class SystemEditPageComponent implements OnInit {

  systemNumber: string | null = '';

  constructor(private currentRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.currentRoute.paramMap.subscribe((params: ParamMap) => {
      this.systemNumber = params.get('systemNumber');
      console.log(this.systemNumber);
    });
  }

}
