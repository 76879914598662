import { Component } from '@angular/core';

@Component({
  selector: 'app-secure-page',
  templateUrl: './secure-page.component.html',
  styleUrls: ['./secure-page.component.scss']
})
export class SecurePageComponent {

}
