<span class="mr-2" *ngIf="isLoggedIn && userprofile && userprofile.firstName">
  {{'profile.welcome' | translate}}, {{userprofile.firstName}}
</span>
<p-avatar *ngIf="isLoggedIn"
          (click)="avatarMenu.toggle($event)"
          icon="pi pi-user"
          shape="circle"
          size="large"
          [style]="{'background-color':'#005597', 'color': '#ffffff'}">
</p-avatar>
<p-tieredMenu #avatarMenu [popup]="true" [model]="items" ></p-tieredMenu>
