import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component( {
  selector: 'app-lang-switcher',
  templateUrl: './lang-switcher.component.html',
  styleUrls: ['./lang-switcher.component.scss']
} )
export class LangSwitcherComponent {
  constructor(private translateService: TranslateService ) {
  }

  switchLang( lang: string ) {
    this.translateService.use(lang);
  }
}
