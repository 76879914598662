import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Chart} from "./chart";
import {Observable} from "rxjs";
import {DataPoint} from "./data-point";

@Injectable({
  providedIn: 'root'
})
export class ChartService {

  constructor(private http: HttpClient) {
  }

  availableCharts(system: string): Observable<Chart[]> {
    return this.http.get<Chart[]>(`${environment.api}/charts/${system}`);
  }

  getData(system: string, chartName: string): Observable<DataPoint[]> {
    return this.http.get<DataPoint[]>(`${environment.api}/charts/data/${system}/${chartName}`);
  }

}
