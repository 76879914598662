import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SystemOverview } from '../models/system-overview';
import { environment } from '../../environments/environment';
import { KeycloakService } from 'keycloak-angular';
import {SystemDetail} from "../models/system-detail";
import {SystemInternalInfo} from "./system-internal-info";

@Injectable( {
  providedIn: 'root'
} )
export class KustecSystemService {
  constructor( private http: HttpClient,
               private ks: KeycloakService ) {
  }

  loadAll(): Observable<SystemOverview[]> {
    return this.http.get<SystemOverview[]>( `${environment.api}/systems/all`, {} );
  }

  findOne( systemNumber: string ): Observable<SystemDetail> {
    return this.http.get<SystemDetail>( `${environment.api}/systems/${systemNumber}`, {} );
  }

  internalInfo( systemNumber: string) :Observable<SystemInternalInfo> {
    return this.http.get<SystemInternalInfo>(`${environment.api}/systems/internal/${systemNumber}`, {});
  }
}
