<div class="mb-3">
  <a [routerLink]="'/' + systemNumber" class="text-primary no-underline">
    <i class="pi pi-arrow-left" style="font-size: 1.5rem"></i> zurück zu {{ systemNumber }}
  </a>
</div>

<div class="bg-white border-round-xl p-3 mb-3 line-height-2">
  <h1 class="text-4xl font-semibold">{{ systemNumber }}</h1>

  <div>
    <ul>


      <li>- System Mapping bearbeiten</li>
      <li>- Störung Mapping bearbeiten (vl auch bei system mapping)</li>
      <li>- Dashboard URL</li>
      <li>- VNC URL</li>
      <li>- Charting und Graphen bearbeiten (Queries dgl)</li>
      <li>- (optional) Standort bearbeiten (in CRM nicht so viele ausgefüllt)</li>
      <li>- (optional) Bild hinzufügen</li>

    </ul>
  </div>
</div>
