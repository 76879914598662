import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, RouterLink} from "@angular/router";
import {KustecSystemService} from "../../services/kustec-system.service";
import {SystemDetail} from "../../models/system-detail";
import {AsyncPipe, NgFor, NgIf} from "@angular/common";
import {SystemDataService} from "../../services/system-data.service";
import {ChartModule} from "primeng/chart";
import {DataPoint} from "../../services/data-point";
import {TranslateService} from "@ngx-translate/core";
import {ChartService} from "../../services/chart.service";
import {Chart} from "../../services/chart";
import {concatMap, forkJoin, from, map, Observable, of, switchMap, toArray} from "rxjs";
import { format } from 'date-fns';
import {ButtonModule} from "primeng/button";
import {KeycloakService} from "keycloak-angular";
import {SystemInternalInfo} from "../../services/system-internal-info";  // Import format function from date-fns

@Component({
  selector: 'app-system-detail-page',
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    ChartModule,
    AsyncPipe,
    RouterLink,
    ButtonModule
  ],
  templateUrl: './system-detail-page.component.html',
  styles: ``
})
export class SystemDetailPageComponent implements OnInit {
  currentSystem: SystemDetail | undefined;
  // availableCharts: Chart[] = [];
  availableCharts$: Observable<Chart[]> | undefined;
  internalInfo$: Observable<SystemInternalInfo> | undefined;
  data: any[] = [];

  constructor(private currentRoute: ActivatedRoute,
              private systemService: KustecSystemService,
              private dataService: SystemDataService,
              private translateService: TranslateService,
              private chartService: ChartService,
              private cd: ChangeDetectorRef,
              public keycloakService: KeycloakService) {
  }

  ngOnInit(): void {

  // this.keycloakService.isUserInRole()
    console.log('this.translateService.currentLang');

    this.currentRoute.paramMap.subscribe((params: ParamMap) => {
      let systemNumber = params.get('systemNumber');
      if (systemNumber) {
        console.log('System number: ' + systemNumber);

        // new 4
        this.systemService.findOne(systemNumber).pipe(
          switchMap((system: SystemDetail) => {
            this.currentSystem = system;
            return this.chartService.availableCharts(system.systemNumber);
          }),
          switchMap((charts: Chart[]) => {
            // Convert the charts array into an observable stream
            return from(charts).pipe(
              // Process each chart sequentially
              concatMap((chart: Chart) => {
                console.log('load chart: ' + chart.chartName);
                if (this.currentSystem && chart.chartName) {
                  console.log(`'load chart exists: ${this.currentSystem.systemNumber} - ${chart.chartName}`);
                  return this.chartService.getData(this.currentSystem.systemNumber, chart.chartName).pipe(
                    // Emit an object containing the chart and its data points
                    map((dataPoints: DataPoint[]) => ({
                      chart,
                      dataPoints
                    }))
                  );
                }
                // If conditions are not met, return an empty data points array for this chart
                return of({
                  chart,
                  dataPoints: []
                });
              }),
              // Collect all results into an array
              toArray()
            );
          })
        ).subscribe(
          (results: { chart: Chart, dataPoints: DataPoint[] }[]) => {
            console.log('All data points: ', results);
            this.data = []; // Initialize the local data array

            results.forEach(result => {
              // Collect all unique timestamps for this chart
              const allTimestamps = Array.from(new Set(result.dataPoints.map(point => point.timestamp))).sort();

              // Format timestamps for better readability
              const formattedTimestamps = allTimestamps.map(timestamp => format(new Date(timestamp), 'MMM dd, yyyy HH:mm'));

              // Initialize the datasets map
              const datasetsMap: { [key: string]: Dataset } = {};

              // Group data points by displayNameDE
              result.dataPoints.forEach(point => {
                if (!datasetsMap[point.displayNameDE]) {
                  datasetsMap[point.displayNameDE] = {
                    label: point.displayNameDE,
                    data: new Array(allTimestamps.length).fill(null), // Initialize array with null values
                    fill: false,
                    borderColor: this.getRandomColor(), // Add a method to get random colors
                  };
                }
                const index = allTimestamps.indexOf(point.timestamp);
                if (index !== -1) {
                  datasetsMap[point.displayNameDE].data[index] = point.value;
                }
              });

              // Convert the datasets map into an array
              const datasets = Object.values(datasetsMap);

              // Prepare the chart data with options including the title
              const chartData = {
                labels: formattedTimestamps,  // Use formatted timestamps
                datasets: datasets,
                options: {
                  responsive: true,
                  plugins: {
                    legend: {
                      position: 'top',
                    },
                    title: {
                      display: true,
                      text: result.chart.chartName
                    }
                  }
                }
              };

              // Push the chart data into the local data array
              this.data.push(chartData);
            });


            console.log('All chart data:', this.data);

          },
          (error) => {
            // Handle errors here
            console.error(error);
          }
        );

        if(this.keycloakService.isUserInRole('app_kustec_admin')) {
          // this.systemService.internalInfo(systemNumber).subscribe((value: SystemInternalInfo) => {
          //   console.log('SystemDetail: ' + JSON.stringify(value));
          // });
          this.internalInfo$ = this.systemService.internalInfo(systemNumber);

        }
      }
    }
    );


    // console.log('tryout dry')
    // this.chartService.getData('Pilzzucht', 'Raum Temperatur').subscribe((value: DataPoint[]) => {
    //   console.log('WTF values!: ' + JSON.stringify(value));
    // });


    // this.data = {
    //   labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    //   datasets: [
    //     {
    //       label: 'First Dataset',
    //       data: [65, 59, 80, 81, 56, 55, 40],
    //       fill: false,
    //       borderColor: '#4bc0c0'
    //     },
    //     {
    //       label: 'Second Dataset',
    //       data: [28, 48, 40, 19, 86, 27, 90],
    //       fill: false,
    //       borderColor: '#565656'
    //     }
    //   ]
    // }


  }


  private groupByOriginalSystemFieldName(dataArray: DataPoint[]) {
    const groupedData: { [key: string]: DataPoint[] } = {};

    dataArray.forEach(item => {
      if (!groupedData[item.originalSystemFieldName]) {
        groupedData[item.originalSystemFieldName] = [];
      }
      groupedData[item.originalSystemFieldName].push(item);
    });

    return groupedData;
  }

  private getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  protected readonly JSON = JSON;
}

// Define an interface for the datasets map
interface Dataset {
  label: string;
  data: (number | null)[];
  fill: boolean;
  borderColor: string;
}
// Define an interface for the chart data entry
// interface ChartDataEntry {
//   chartName: string;
//   data: {
//     labels: Date[];
//     datasets: Dataset[];
//     options?: any;
//   };
// }
