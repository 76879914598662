<div class="mb-3">
  <a routerLink="/" class="text-primary no-underline">
    <i class="pi pi-arrow-left" style="font-size: 1.5rem"></i> zurück zur Übersicht
  </a>
</div>

<div *ngIf="currentSystem">

  <div class="bg-white border-round-xl p-3 mb-3 line-height-2">
    <div class="flex">
      <div class="flex flex-grow-1">
        <div>
          <h1 class="text-4xl font-semibold">{{ currentSystem.systemNumber }}</h1>
          <div>{{ currentSystem.systemName }}</div>

          <div class="text-gray-600">
            <div>
              <span>{{ currentSystem.street }} </span>
              <span>{{ currentSystem.houseNr }}</span>
            </div>
            <div>
              <span>{{ currentSystem.zipCode }} </span>
              <span>{{ currentSystem.city }} </span>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-none">
        <div *ngIf="keycloakService.isUserInRole('app_kustec_admin')">
          <a [routerLink]="['/edit', currentSystem.systemNumber]"
             class="p-button p-button-secondary no-underline">
            Anlage bearbeiten
          </a>
        </div>
      </div>
    </div>

  </div>


  <div *ngIf="availableCharts$ | async; let ac">
    <h2>Verfügbar Charts</h2>
    <div *ngFor="let chart of ac">
      <div>
        <span>{{ chart.systemNumber }}</span>
        <span>{{ chart.chartName }}</span>
        <span>{{ chart.info }}</span>
      </div>
    </div>
    Ende verfügbarer Charts
  </div>

  <div *ngIf="keycloakService.isUserInRole('app_kustec_admin')"
       class="bg-white border-round-xl p-3 mb-3 line-height-2">
    <span class="text-2xl">
      <i class="pi pi-lock" style="font-size: 1.5rem"></i>
      KUSTEC only section
    </span>
    <div class="text-gray-600">
      Dieser Bereich ist nur für Kustec Mitarbeiter sichtbar.
      Endkunden haben hier keinen Zugriff
    </div>
    <div class="flex gap-3 mt-2" *ngIf="internalInfo$ | async; let ii">
      <a *ngIf="ii.grafanaDashboardUrl"
         [href]="ii.grafanaDashboardUrl"
         target="_blank"
         class="p-button no-underline p-button-secondary">
        Grafana Dashboard
      </a>
      <a *ngIf="ii.vncUrl"
         [href]="ii.vncUrl"
         target="_blank"
         class="p-button no-underline p-button-secondary">
        VNC
      </a>

    </div>
  </div>

  <div class="grid">
    <div class="col-6" *ngFor="let d of data">
      <div class="bg-white border-round-xl p-3">
        <p-chart type="line" [data]="d" [options]="d.options"></p-chart>
      </div>
    </div>
  </div>

</div>
