import { Component, OnDestroy, OnInit } from '@angular/core';
import { KustecSystemService } from '../../services/kustec-system.service';
import { Observable } from 'rxjs';
import { SystemOverview } from '../../models/system-overview';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-start-page',
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.scss']
})
export class StartPageComponent implements OnInit{
  // @ts-ignore
  systems: SystemOverview[] = [];

  constructor(private systemService: KustecSystemService,
              public keycloakService: KeycloakService) {
  }

  ngOnInit(): void {
    this.systemService.loadAll()
      .subscribe( value => this.systems = value);

    console.log('userroles in startpage');
    console.log(this.keycloakService.getUserRoles());
  }

  print(e:any) {
    console.log(e.target.value);
    return e.target.value;
  }


}
