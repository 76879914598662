<div class="flex align-content-center">
  <div class="flex align-items-center">
    <img src="https://i.seadn.io/gae/mwjY2BuBSsiRuK1QzJNiPKl6C7ZshEdMePUQK651c2E4bXLZyiDN2l29ZFbHeZ3totWrQKmh14XpV9VukewEsw1wCSJqJZjRZGrD?auto=format&dpr=1&w=3840"
         class="border-circle w-6rem h-6rem m-2 bg-primary font-bold flex align-items-center justify-content-center"  alt="person image"/>
  </div>
  <div class="flex align-items-center">
    <div class="line-height-2">
      <div class="font-bold">Thomas Schmid</div>
      <div>Kundenbetreuer:in</div>
      <div class="mt-2">+43 1 222 333</div>
    </div>
  </div>
</div>
